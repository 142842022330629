// npm
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box, BoxProps, styled } from '@mui/material'
import { TbPlayerPlayFilled } from 'react-icons/tb'

// components
import GlowIconButton from '@atoms/buttons/GlowIconButton'

interface Props extends BoxProps {
  youtubeUrl: string
  fallbackImage: string
}

const PlayerWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  height: 0,
  paddingTop: '56.25% !important',
}))
const StyledReactPlayer = styled(ReactPlayer)({
  position: 'absolute',
  top: 0,
  left: 0,
  img: {
    width: '100%',
    height: '100%',
  },
})

const YoutubeMockupCollection = ({
  youtubeUrl,
  fallbackImage,
  ...props
}: Props) => {
  return (
    <PlayerWrapper>
      <StyledReactPlayer
        light={<img src={fallbackImage} alt="" />}
        playing
        controls
        url={youtubeUrl}
        width="100%"
        height="100%"
        playIcon={
          <GlowIconButton>
            <TbPlayerPlayFilled />
          </GlowIconButton>
        }
      />
    </PlayerWrapper>
  )
}

export default YoutubeMockupCollection
