// npm
import React from 'react'
import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  darken,
  styled,
} from '@mui/material'

interface Props extends IconButtonProps {
  wrapperProps: BoxProps
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '140px',
  height: '140px',
}))

const OuterGlow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '130px',
  height: '130px',
  opacity: 0.65,
  border: `2px solid ${theme.palette.primary.main}`,
  filter: 'blur(5px)',
  borderRadius: '50%',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%,-50%,0)',
}))
const InnerGlow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  boxSizing: 'border-box',
  width: '110px',
  height: '110px',
  opacity: 0.85,
  border: `2px solid ${theme.palette.primary.main}`,
  filter: 'blur(3px)',
  borderRadius: '50%',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%,-50%,0)',
}))
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  width: '100px',
  height: '100px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%,-50%,0)',
  color: 'white',
  transition: theme.transitions.create(['background-color']),
  ['&:hover']: {
    background: darken(theme.palette.primary.main, 0.1),
  },
}))

const GlowIconButton = ({ wrapperProps, ...props }: Props) => {
  return (
    <Wrapper {...wrapperProps}>
      <OuterGlow />
      <InnerGlow />
      <StyledIconButton {...props} />
    </Wrapper>
  )
}

export default GlowIconButton
